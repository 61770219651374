<template>
  <div class="container">

    <memlist-modal
      size="lg"
      ref="edit-form"
      hide-footer
      >
      <div class="custom-editor">
        <form @submit.prevent="on_save_clicked">

          <m-row>
            <m-col>
              <memlist-text-input
                id="name"
                name="name"
                :title="$t('REPORT.NAME')"
                layout="vertical"
                v-model="local_item.name"
                :required="true"
              />
            </m-col>
          </m-row>

          <m-row>
            <m-col>
              <memlist-textarea
                id="query"
                name="query"
                :title="$t('REPORT.QUERY')"
                layout="vertical"
                v-model="local_item.query"
                :required="true"
              />
            </m-col>
          </m-row>

          <m-row>
            <m-col>
              <memlist-checkbox 
                :text="$t('REPORT.HAS_COMPANY_ID')"
                v-model="local_item.has_company_id"
              />
            </m-col>
          </m-row>
          
          <m-row>
            <m-col>
              <memlist-checkbox 
                :text="$t('REPORT.HAS_PERIOD_ID')"
                v-model="local_item.has_period_id"
              />
            </m-col>
          </m-row>

          <m-row>
            <m-col>
              <memlist-checkbox 
                :text="$t('REPORT.HAS_FROM_DATE')"
                v-model="local_item.has_from_date"
              />
            </m-col>
          </m-row>
          
          <m-row>
            <m-col>
              <memlist-checkbox 
                :text="$t('REPORT.HAS_TO_DATE')"
                v-model="local_item.has_to_date"
              />
            </m-col>
          </m-row>

          <m-row>
            <m-col>
              <memlist-select-input2
                id="script"
                name="script"
                :title="$t('REPORT.SCRIPT')"
                layout="vertical"
                validation_type="TEXT"
                v-model="local_item.script"
                :items="script_options"
                :required="false"
                :invalid_text="''"
                :info_text="''"
              />

            </m-col>
          </m-row>

        </form>
      </div>

      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="on_save_clicked"
        @close="on_close_clicked"
        ref="saveButton"
      />
    </memlist-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'CustomReportEditorModal',

  props: ['id'],
  emits: ['created', 'updated'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    id: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.id) {
          this.load_item(this.id);
        }
      },
      immediate: true
    },
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId','customer']),

  },

  methods: {

    on_close_clicked() {
      this.hide();
    },

    show() {
      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    async on_save_clicked() {

      if (this.local_item.id) {
        await this.put_item(this.local_item);
      }
      else {
        await this.post_item(this.local_item);
      }

      this.$refs['saveButton'].stop();
    },

    async post_item(item) {
      const res = await axios.post(`/custom_report/${this.customer.id}`, item);

      if (res.status === 201) {
        this.local_item = res.data;

        this.$emit('created', this.local_item);
      }
    },

    async put_item(item) {
      const res = await axios.put(`/custom_report/${item.id}`, item);

      if (res.status === 204) {
        this.$emit('updated', this.item);
      }
    },

    async load_item(id) {
      const res = await axios.get(`/custom_report/${id}`);

      this.local_item = res.data;
    }
  },

  mounted() {

  },

  data() {
    return {
      local_item: {},
      script_options: [
        { text: 'Inget', value: 'NONE' },
        { text: 'UNG1', value: 'UNG1' },
        { text: 'UNG2', value: 'UNG2' },
        { text: 'UNG3', value: 'UNG3' },
        { text: 'SALES', value: 'SALES' },
        { text: 'REGMEMBERS', value: 'REGMEMBERS' },
        { text: 'SOLD_MEMBERSHIPS_SUMMARY', value: 'SOLD_MEMBERSHIPS_SUMMARY' },
        { text: 'SOLD_MEMBERSHIPS_SECTION', value: 'SOLD_MEMBERSHIPS_SECTION' },
        
      ],
    };
  }
};

</script>


<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
