<template>
  <div>
    <b-row>

      <b-col cols="6" class="d-flex justify-content-begin align-left">
        <a
          class="btn btn-primary font-weight-bolder font-size-sm mt-4 aligns"
          style="max-height: 40px; margin-top: 20px !important;"
          @click.prevent="download_report"
        >
          {{ $t('PAGES.REPORTS.DOWNLOAD_MONTH_REPORT') }}
        </a>

        <b-form-group v-if="!loading" :label="$t('PAGES.REPORTS.FROM_DATE')" label-for="due_date-input" class="ml-4">
          <memlist-date-picker v-model="from_date"></memlist-date-picker>
        </b-form-group>

        <b-form-group v-if="!loading" :label="$t('PAGES.REPORTS.TO_DATE')" label-for="due_date-input" class="ml-4">
          <memlist-date-picker v-model="to_date"></memlist-date-picker>
        </b-form-group>

        <memlist-spinner v-if="loading" />
      </b-col>
    </b-row>

  </div>
</template>



<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  mixins: [ toasts ],
  name: 'ExportUngReport1Button',
  components: {},
  props: [],
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'initComplete'])
  },
  watch: {

  },
  data() {
    return {
      month: null,
      loading: false,
      from_date: null,
      to_date: null
    };
  },
  mounted() {

  },
  methods: {
    async download_report() {
      try {

        if (!this.from_date || !this.to_date) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REPORTS.MUST_SELECT_INTERVAL'));
          return;
        }

        this.loading = true;

        const res = await axios.post(`/company/monthreport`, {
          from_date: this.from_date,
          to_date: this.to_date
        });

        if (res.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          this.toastr('success', this.$t('COMMON.OK'), 'Rapport skapad');
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel status');
        }

        this.loading = false;
      }
      catch (err) {
        this.loading = false;

        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa rapport');
      }

    },

  }
};
</script>
<style scoped>
  .aligns {
    float: left;
    max-width: 200px;
  }
</style>
